import {
    Datagrid,
    EditButton,
    DateField,
    TextField,
    ReferenceArrayField,
    BooleanField,
    Create,
    CheckboxGroupInput, SimpleForm,
    BooleanInput, NumberInput,
    Edit, Show, SimpleShowLayout,
    FunctionField,
    NumberField,
    useTranslate,
    ReferenceManyField,
    Pagination, Toolbar, SaveButton
} from 'react-admin';

import React from "react";
import {Redirect} from "react-router-dom";

export  const CurriculumList = () => <Redirect to="/your_courses" />;

const days=[
        { id: 'Mon', name: 'resources.curricula.days.mo'},
        { id: 'Tue', name: 'resources.curricula.days.tu' },
        { id: 'Wed', name: 'resources.curricula.days.we' },
        { id: 'Thu', name: 'resources.curricula.days.th' },
        { id: 'Fri', name: 'resources.curricula.days.fr' },
        { id: 'Sat', name: 'resources.curricula.days.sa' },
        { id: 'Sun', name: 'resources.curricula.days.su' },
];
export  const CurriculumCreate = props => {
    const translate = useTranslate();
    return (
        <Create {...props}>
            <SimpleForm redirect={"show"}>
                <CheckboxGroupInput label={"resources.curricula.fields.weekDays"}
                                    source={"weekDays"}
                                    choices={days}
                />
                <BooleanInput source={"isnotify"} label={"resources.curricula.fields.isnotify"} />
                <p>{translate("resources.curricula.fields.minutCount")}</p>
                <NumberInput source={"minutCount"} label={""} />
            </SimpleForm>
        </Create>
    );
};
const CurriculumTitle = ({ record }) =>{
    const translate = useTranslate();
    return(<span>{ translate('resources.curricula.name')}</span>);
};
const PostEditToolbar = props => (
    <Toolbar {...props} >
        <SaveButton />
    </Toolbar>
);
export  const CurriculumEdit= props => {
    const translate = useTranslate();
    return(
        <Edit {...props}
              title={<CurriculumTitle />}
        >
            <SimpleForm  redirect="show" toolbar={<PostEditToolbar />}>
                <CheckboxGroupInput label={"resources.curricula.fields.weekDays"}
                                    source={"weekDays"}
                                    choices={days}
                />
                <BooleanInput source={"isnotify"} label={"resources.curricula.fields.isnotify"} />
                <p>{translate("resources.curricula.fields.minutCount")}</p>
                <NumberInput source={"minutCount"} label={""} />
            </SimpleForm>
        </Edit>
    )
};

export const CurriculumShow = props => {
    return(
        <Show {...props}
              title={<CurriculumTitle />}
        >
            <SimpleShowLayout
            >
                <DateField label={"resources.curricula.fields.deadline"} source="deadline"/>
                <ReferenceManyField
                    reference="weeks"
                    target="curriculum"
                    addLabel={false}
                    pagination={<Pagination />}
                    sort ={{field: 'name', order: 'ASC' }}
                    fullWidth
                >
                    <Datagrid style={{marginBottom:"10px"}}>
                        <BooleanField source="done" label={"resources.curricula.fields.done"}/>
                        <FunctionField label={"resources.curricula.fields.name"} render={record => `Woche ${record.name}`} />
                        <NumberField source="learningDays" label={"resources.curricula.fields.learningDays"} />
                        <TextField source="Notice" label={"resources.curricula.fields.notices"}/>
                        <EditButton />
                    </Datagrid>
                </ReferenceManyField>
                {/*<ReferenceArrayField
                    reference="weeks"
                    source="weeks"
                    label=""
                    pagination={<Pagination />}
                >
                    <Datagrid>
                        <BooleanField source="done" label={"resources.curricula.fields.done"}/>
                        <FunctionField label={"resources.curricula.fields.name"} render={record => `Woche ${record.name}`} />
                        <NumberField source="learningDays" label={"resources.curricula.fields.learningDays"} />
                        <TextField source="Notice" label={"resources.curricula.fields.notices"}/>
                        <EditButton />
                    </Datagrid>
                </ReferenceArrayField >*/}
            </SimpleShowLayout>
        </Show>
    )
};