import React, { useState } from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import {useTranslate,Loading , Title, useNotify, useDataProvider} from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import {useHistory} from "react-router-dom";
import GetAppIcon from "@material-ui/icons/GetApp";

const useStyles = makeStyles({
    label: { width: '10em', display: 'inline-block' },
    button: { margin: '1em',display: 'block' },
});

const ManualAdd = () => {
    const translate = useTranslate();
    const [loading, setLoading] = useState(false);
    const handleChange = (e) => {
        const file = e.target.files && e.target.files[0];

    };
    if (loading) return <Loading />;
    return (
        <Card>
            <Title title={translate('resources.home_works.Answer')} />
            <CardContent >
                <input
                    type="file"
                    id="text-button-file"
                    style={{ display: 'none' }}
                    accept='.csv'
                    onChange={handleChange}
                />
                <label htmlFor="text-button-file" style={{ display: 'inline-flex', alignItems: 'center',marginLeft: "15px" }}>
                    <Button
                        color="default"
                        component="span"
                        variant="contained"
                    >
                        <GetAppIcon style={{ transform: 'rotate(180deg)', fontSize: 20 }} />
                        <span style={{ paddingLeft: '0.5em' }}>{translate('pos.participant.import')}</span>
                    </Button>
                </label>
            </CardContent>
        </Card>
    );
};

export default ManualAdd;
