import React  from "react";
import {
    TextField,
    ReferenceField, SelectInput,
    RichTextField,
    ReferenceInput,
    FunctionField,
    useTranslate,
    Show,
    Filter, SearchInput, Datagrid,List,ShowButton,
    DateField,
    SimpleShowLayout
} from 'react-admin';
import RichTextInput from 'ra-input-rich-text';
import FullNameField from "../users/FullNameField"
import {Redirect} from "react-router";

const NotificationTitle = ({ record }) => <span>{record.name}</span>;
export  const NotificationCreate = () => (<Redirect to={'/your_courses'}/>);
export  const NotificationEdit= () => (<Redirect to={'/your_courses'}/>);
export  const NotificationsList= () => (<Redirect to={'/your_courses'}/>);
export  const NotificationShow = props => {
    return(
        <Show {...props} title={<NotificationTitle/>} hasEdit={false}>
            <SimpleShowLayout>
                <TextField source={"name"} />
                <RichTextField  source={"content"} />
                <DateField source={"createdAt"} />
                <TextField source={"senderName"} />
            </SimpleShowLayout>
        </Show>
    );
};

/*const SearchFilter = props => {
    const translate = useTranslate();
    return (
    <Filter {...props}>
        <SearchInput source="name" alwaysOn placeholder={translate('resources.notifications.fields.name')}/>
        <RichTextInput  source={"content"} />
        <ReferenceInput reference="users" source="sender"
                        sort={{ field: 'firstName', order: 'ASC' }}
                        perPage={9999999999999999}>
            <SelectInput optionText={<FullNameField/>} />
        </ReferenceInput>
    </Filter>
)};
export  const NotificationsListOld = props => {
    const user = localStorage.getItem('user');
    const permissions = user ? JSON.parse(localStorage.getItem('user')).permissions : [];
    return(
        <List {...props}
                     hasCreate={false}
                     hasEdit={false}
                     hasShow={permissions.find(function(element) {return element.module === 'Notification' && element.permission==='READ'})!==undefined}
                     exporter={false}
                     sort={{ field: 'createdAt', order: 'DESC' }}
                     filters={<SearchFilter/>}
                     bulkActionButtons={false}
        >
            <Datagrid
            >
                <TextField source={"name"} />
                <FunctionField  resource="users"  label='resources.home_works.fields.description' render={(record)=> {
                    const text = record.content.replace(/(<([^>]+)>)/ig,"");
                    const dot = (text.length > 50)?"...":"";
                    return text.substr(0,50)+""+dot;
                    }}
                />
                <ReferenceField reference="users" source="sender">
                    <FullNameField />
                </ReferenceField>
                {permissions.find(function (element) {
                    return element.module === 'Notification' && element.permission === 'READ'
                }) !== undefined &&
                    <ShowButton/>
                }
            </Datagrid>
        </List>
    )
};*/

