import React from 'react';
import {
    DateField,
    TextField,
    useTranslate,
    SimpleShowLayout,
    FunctionField,
    Show,
    BooleanField,
    EmailField,
    Tab,
    TabbedShowLayout,
    useShowController
} from 'react-admin';


import FullNameField from './FullNameField';
const UserTitle = ({ record }) => record ? <FullNameField record={record} size="32" /> : null;

const UserShow = props => {
    const translate = useTranslate();
    const { record } = useShowController(props);
    return(
        <Show{...props} title={<UserTitle/>}
        >
            <SimpleShowLayout>
                <TabbedShowLayout>
                    <Tab  label="resources.customers.tabs.infoPersonnel">
                        {/*<FullNameField {...props} />*/}
                        <EmailField source="email" type="email" />
                        <TextField source="phone" type="phone" />
                        <DateField source="birthday" type="date" />
                        <FunctionField  resource="users"  label='resources.users.fields.gender' render={(record)=> {
                            if(record.gender === 0)
                                return translate('resources.users.fields.genders.male');
                            if(record.gender === 1)
                                return translate('resources.users.fields.genders.female');
                            if(record.gender === 2)
                                return  translate('resources.users.fields.genders.other');
                        }}
                        />
                        <FunctionField  resource="users"  label='resources.users.fields.address' render={(record)=> {
                                return record.street+", "+record.zip+" "+record.city;
                        }}
                        />
                        {/*<BooleanField resource="users" source="active" label="Active" />*/}
                    </Tab>
                </TabbedShowLayout>
            </SimpleShowLayout>
        </Show>
    );
};




export default UserShow;
