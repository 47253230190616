import {
    ReferenceField,
    ReferenceInput,
    SelectInput,
    DateField,
    List,
    Datagrid,
    TextField,
    Filter,
    SearchInput,
    DateInput,
    downloadCSV,
    useTranslate
} from "react-admin";
import MButton from '@material-ui/core/Button';
import React from "react";
import FullNameField from "../users/FullNameField"
import jsonExport from 'jsonexport/dist';
import Moment from 'moment';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import { Redirect } from "react-router-dom";
import {replaceUmlaute} from "../utils/Umlaut";

export  const BookingCreate = () => <Redirect to="/" />;
export  const BookingEdit = () => <Redirect to="/" />;
export  const BookingShow = () => <Redirect to="/" />;

const SearchFilter = props => {
    const translate = useTranslate();
    return (
    <Filter {...props}>
        <SearchInput source="invoiceNbr" alwaysOn placeholder={translate('resources.bookings.fields.invoiceNbr')}/>
        <DateInput source="createdAt"/>
        <DateInput source="updatedAt"/>
        <DateInput source="accessUntil"/>
        {/*<ReferenceInput reference="your_courses"
                        source="course"
                        filter={{customerCourse:true}}
                        sort={{ field: 'name', order: 'ASC' }}
                        perPage={9999999999999999}>
            <SelectInput source="name" />
        </ReferenceInput>*/}
        <TextField source="courseName"/>
        <ReferenceInput reference="booking_statuses" source="bookingStatus"
                        sort={{ field: 'name', order: 'ASC' }}
                        perPage={9999999999999999}>
            <SelectInput source="name" />
        </ReferenceInput>
        <ReferenceInput reference="payments" source="payment"
                        sort={{ field: 'name', order: 'ASC' }}
                        perPage={9999999999999999}>
            <SelectInput source="name" />
        </ReferenceInput>
    </Filter>
)};
const exporter = (records,fetchRelatedRecords) => {
    const courses = fetchRelatedRecords(records, 'course', 'courses');
    const payments = fetchRelatedRecords(records, 'payment', 'payments');
    const booking_statuses = fetchRelatedRecords(records, 'bookingStatus', 'booking_statuses');
    Promise.all([courses,payments,booking_statuses]).then((response)=>{
        const data = records.map(record => ({
            invoiceNbr: record.invoiceNbr,
            course: (response[0][record.course]) ? replaceUmlaute(response[0][record.course].name) :"",
            payment: (response[2][record.payment])? replaceUmlaute(response[2][record.payment].name) : "",
            booking_status: (response[3][record.bookingStatus])? replaceUmlaute(response[3][record.bookingStatus].name) : "",
            createdAt: Moment(record.createdAt).format('DD.MM.YYYY'),
            updatedAt: Moment(record.updatedAt).format('DD.MM.YYYY'),
            AccessUntil: Moment(record.accessUntil).format('DD.MM.YYYY'),
        }));
        jsonExport(data,{
            rowDelimiter: ';'
        },(err, csv) => {
            downloadCSV(csv, 'bookings');
        });
    })
};
/*const InvoiceDownloadButton=({record})=>{
    const translate = useTranslate();
    return (
        <div>
            { record.bookingStatus === "/api/booking_statuses/1" ?
                <Button startIcon={<CloudDownloadIcon/>}
                    // variant="contained"
                        color="primary"
                        href={record.invoicePdfLink}
                        target="_blank"
                        download>
                    {translate('resources.bookings.invoiceBtn')}
                </Button>
                : record.bookingStatus === "/api/booking_statuses/3" ?
                    <Button startIcon={<CloudDownloadIcon/>}
                        // variant="contained"
                            style={{color:'#f44336',fontWeight:'bold'}}
                            href={record.invoicePdfLink}
                            target="_blank"
                            download>
                        {translate('resources.bookings.invoiceStornoBtn')}
                    </Button>
                    :null
            }
        </div>
    )
};*/

const InvoiceDownloadButton=({record})=>{
    const translate = useTranslate();
    return (
        <div>
            { record.invoicePdfLink ?
                <MButton startIcon={<CloudDownloadIcon/>}
                    // variant="contained"
                         color="primary"
                         href={record.invoicePdfLink}
                         target="_blank"
                         download>
                    {translate('resources.bookings.invoiceBtn')}
                </MButton>
                :null
            }
        </div>
    )
};

const StronoDownloadButton=({record})=>{
    const translate = useTranslate();
    return (
        <div>
            { record.stornoPdfLink ?
                <MButton startIcon={<CloudDownloadIcon/>}
                    // variant="contained"
                         style={{color:'#f44336',fontWeight:'bold'}}
                         href={record.stornoPdfLink}
                         target="_blank"
                         download>
                    {translate('resources.bookings.invoiceStornoBtn')}
                </MButton>
                :null
            }
        </div>
    )
};
export  const BookingsList = props =>{
    return (
        <List {...props}
              hasCreate={false}
              filters={<SearchFilter/>}
              sort={{ field: 'updatedAt', order: 'DESC' }}
              exporter={false}
              bulkActionButtons={false}
        >
            <Datagrid>
                <TextField source={"invoiceNbr"}/>
                {/*<ReferenceField reference="courses" source="course">
                    <TextField source="name" />
                </ReferenceField>*/}
                <TextField source="courseName"/>
                <ReferenceField reference="payments" source="payment" link={false}>
                    <TextField source="name" />
                </ReferenceField>
                <ReferenceField reference="booking_statuses" source="bookingStatus" link={false}>
                    <TextField source="name" />
                </ReferenceField>
                <DateField source="createdAt"/>
                <DateField source={"updatedAt"}/>
                <DateField source={"accessUntil"}/>
                <InvoiceDownloadButton />
                <StronoDownloadButton />
            </Datagrid>
        </List>
    );
};