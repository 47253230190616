import React from 'react';
import {
    DateInput,
    Filter,
    List, NumberInput,
     SearchInput, TextInput
} from 'react-admin';
import GridList from './GridList';

/*const SearchFilter = props => (
    <Filter {...props}>
        <SearchInput source="name" alwaysOn />
        <TextInput source="level" />
        <DateInput source="accessUntil" />
        <NumberInput source="hourCount" />
        <NumberInput source="price" />
    </Filter>
);*/
const CourseList = props => {
    return (
        <List
            {...props}
            // filters={false}
            sort={{field: 'accessUntil', order: 'ASC'}}
            hasCreate={false}
            exporter={false}
            perPage={25}
            // pagination={<Pagination />}
        >
            <GridList />
        </List>
    )
};

export default CourseList;