import {
    Show,
    TopToolbar,
    useShowController,
    useTranslate,
    useNotify,
    TabbedShowLayout,
    Tab, RichTextField, TextField,
    Datagrid, ReferenceManyField, FunctionField,
    Pagination,ReferenceField
} from "react-admin";
import IframeField from "./IframeField";
import React,{ useState }  from "react";
import { Button } from '@material-ui/core';
import { Link } from 'react-router-dom';
import CalendarViewDayIcon from '@material-ui/icons/CalendarViewDay';
import VisibilityIcon from '@material-ui/icons/Visibility';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import { green } from '@material-ui/core/colors';
import TextFieldd from '@material-ui/core/TextField';

import  FullNameField from '../users/FullNameField';


const CourseTitle = ({ record }) => <span>{record.name}</span>;

const YourCourseShowActions = ({ record }) => {
    const id = encodeURIComponent(record.curriculum);
    const pathname = (id === 'null') ? `/curricula/create`  : `/curricula/${id}/show`;
    localStorage.setItem('bookingId',record.bk);
    const translate = useTranslate();
    return(
        <TopToolbar>

                <Button component={Link}
                        to={{pathname: `${pathname}`}}
                        variant="contained" color="primary"
                        startIcon={<CalendarViewDayIcon/>}
                >
                    {id === 'null' ?  translate('resources.curricula.config_name')  : translate('resources.curricula.name') }
                </Button>

        </TopToolbar>
    )
};

const CustomButton = ({record,...props})=>{
    const pathname =  `/answer/${record.id}/`;
    return(
        <Button
            component={Link}
            to={{pathname: `${pathname}`}}
            color="primary"
            // color="primary"
            startIcon={<VisibilityIcon/>}
        >
            Show
        </Button>
    )
}

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        alignItems: 'center',
    },
    wrapper: {
        margin: theme.spacing(1),
        position: 'relative',
    },
    buttonSuccess: {
        backgroundColor: green[500],
        '&:hover': {
            backgroundColor: green[700],
        },
    },
    fabProgress: {
        color: green[500],
        position: 'absolute',
        top: -6,
        left: -6,
        zIndex: 1,
    },
    buttonProgress: {
        color: green[500],
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
    },
}));


/*const Feedback = record =>{
    console.log(record);
    return(

    )
}*/
const YourCourseShow = props => {
    const translate = useTranslate();
    const { record } = useShowController(props);
    const user = JSON.parse(localStorage.getItem('user'));
    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(false);
    const [content, setContent] = useState('');
    const classes = useStyles();
    const notify = useNotify();
    const handleButtonClick = () => {
        if (!loading) {
            setSuccess(false);
            setLoading(true);
            const formData = new FormData();
            formData.append('content', content);
            formData.append('course', record.name );
            formData.append('email', user.email );
            formData.append('name', user.firstName+' '+user.lastName );
            formData.append('token', window.localStorage.getItem("token"));

            const xhr = new XMLHttpRequest();
            // const url = process.env.REACT_APP_FEEDBACK_ENTRYPOINT;
            const url = process.env.REACT_APP_BACKEND_URL+'/feedback';
            xhr.open('POST', url, true);
            xhr.onload = function () {
                if (xhr.status === 200) {
                    // setSuccess(true);
                    setLoading(false);
                    setContent(' ');
                    notify('pos.forgetPassword.successMsg','info')
                } else {
                    setLoading(false);
                    notify('xhr.statusText','error');
                }
            };
            xhr.send(formData);
        }
    };
    return (
        <Show  {...props} title={<CourseTitle/>} actions={record && <YourCourseShowActions record={record}/>} >
            <TabbedShowLayout>
                <Tab  label="resources.courses.tabs.label">
                    <IframeField {...props}  />
                </Tab >
                <Tab  label="resources.courses.tabs.homeWorks" path="home_works">
                    <ReferenceField  source="teacher" reference="courses" link={false}>
                        <FullNameField />
                    </ReferenceField>
                    <ReferenceManyField
                        reference="home_works"
                        target="course"
                        addLabel={false}
                        pagination={<Pagination />}
                        fullWidth
                    >
                        <Datagrid style={{marginBottom:"10px"}} rowClick={"edit"}>
                            <TextField source={"name"} />
                            {/*<RichTextField  source={"description"} />*/}
                            <FunctionField  label='resources.home_works.fields.description' render={(record)=> {
                                const text = record.description.replace(/(<([^>]+)>)/ig,"");
                                const dot = (text.length > 100)?"...":"";
                                return text.substr(0,100)+""+dot;
                            }} />
                            <FunctionField  resource="users"  label='resources.home_works.fields.type' render={(record ) =>{
                                return translate('resources.home_works.type.'+record.type);
                            }} />
                            {/*<CustomButton />*/}
                        </Datagrid>
                    </ReferenceManyField>
                </Tab>
                <Tab  label="Feedback" path="feedback">
                    <div className={classes.wrapper}>
                        <TextFieldd
                            id="content"
                            label="Text"
                            multiline
                            rows={8}
                            variant="filled"
                            onBlur={(e)=>{
                                setContent(e.target.value)
                            }}
                            onChange={(e)=>{
                                setContent(e.target.value)
                            }}
                            fullWidth
                        /><br/><br/>
                        <Button
                            variant="contained"
                            color="primary"
                            className={classes.buttonClassname}
                            disabled={loading || content===''}
                            onClick={handleButtonClick}
                        >
                            {translate('resources.courses.fields.sendFeedbackBtn')}
                        </Button>
                        {loading && <CircularProgress size={24} className={classes.buttonProgress} />}
                    </div>
                </Tab>
            </TabbedShowLayout>
        </Show>
    )
};

export default YourCourseShow;