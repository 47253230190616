import React from 'react';
import {
    Create,
    DateInput,
    SimpleForm,
    TextInput,
    useTranslate,
    PasswordInput,
    required,
    SelectInput,
} from 'react-admin';
import { Typography, Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import {Redirect, Route} from 'react-router-dom';

export const styles = {
    first_name: { display: 'inline-block' },
    last_name: { display: 'inline-block', marginLeft: 32 },
    email: { width: 544 },
    phone: { width: 544 },
    address: { maxWidth: 544 },
    zipcode: { display: 'inline-block' },
    city: { display: 'inline-block', marginLeft: 32 },
    birthday: { display: 'inline-block' },
    gender: { display: 'inline-block', marginLeft: 32 },
    password: { display: 'inline-block' },
    confirm_password: { display: 'inline-block', marginLeft: 32 },
};

const useStyles = makeStyles(styles);

export const validatePasswords = ({
    password,
    confirm_password,
}) => {
    const errors = {};

    if (password && confirm_password && password !== confirm_password) {
        errors.confirm_password = [
            'resources.users.errors.password_mismatch',
        ];
    }

    return errors;
};

const UserCreate = (props) => {
    const classes = useStyles();

    return (
        <Redirect to="/" />
        /*<Create {...props}>
            <SimpleForm validate={validatePasswords}>
                <SectionTitle label="resources.users.fieldGroups.identity" />
                <TextInput
                    autoFocus
                    source="firstName"
                    formClassName={classes.first_name}
                    validate={requiredValidate}
                />
                <TextInput
                    source="lastName"
                    formClassName={classes.last_name}
                    validate={requiredValidate}
                />
                <TextInput
                    type="email"
                    source="email"
                    validation={{ email: true }}
                    fullWidth={true}
                    formClassName={classes.email}
                    validate={requiredValidate}
                    defaultValue={""}
                />
                <DateInput source="birthday"  formClassName={classes.birthday} />
                <SelectInput source="gender" resource="users" formClassName={classes.gender} choices={[
                    { id: 0, name: 'resources.users.fields.genders.male'},
                    { id: 1, name: 'resources.users.fields.genders.female' },
                    { id: 2, name: 'resources.users.fields.genders.other' },
                ]} />
                <TextInput type="tel" source="phone" resource="users" formClassName={classes.phone} fullWidth={true} />
                <Separator />
                <SectionTitle label="resources.users.fieldGroups.address" />
                <TextInput
                    source="address"
                    formClassName={classes.address}
                    multiline={true}
                    fullWidth={true}
                />
                <TextInput source="zip" formClassName={classes.zipcode} />
                <TextInput source="city" formClassName={classes.city} />
                <Separator />
                <SectionTitle label="resources.users.fieldGroups.password" />
                <PasswordInput
                    source="password"
                    formClassName={classes.password}
                />
                <PasswordInput
                    source="confirm_password"
                    formClassName={classes.confirm_password}
                />
            </SimpleForm>
        </Create>*/
    );
};

const requiredValidate = [required()];

const SectionTitle = ({ label }) => {
    const translate = useTranslate();

    return (
        <Typography variant="h6" gutterBottom>
            {translate(label)}
        </Typography>
    );
};

const Separator = () => <Box pt="1em" />;

export default UserCreate;
