import React from 'react';
import {
    DateInput,
    Edit,
    NullableBooleanInput,
    TextInput,
    PasswordInput,
    Toolbar,
    useTranslate,
    NumberInput,
    SelectInput, usePermissions, SaveButton, SimpleForm, required,
    email,
    minLength,
    maxLength,
    regex,
    RadioButtonGroupInput
} from 'react-admin';
import { Box, Card, CardContent, Typography } from '@material-ui/core';
import ImageUploader from 'react-images-upload';

import FullNameField from './FullNameField';
import {useDispatch} from "react-redux";


const EditActions = props => (
    <Toolbar {...props} >
        <SaveButton />
    </Toolbar>
);

export const validatePasswords = ({plainPassword,confirm_password}) => {
    const errors = {};
    if (plainPassword && confirm_password && plainPassword !== confirm_password) {
        errors.confirm_password = [
            'resources.users.errors.password_mismatch',
        ];
    }

    return errors;
};


const UserEdit = (props) => {
    const translate = useTranslate();
    const requiredValidate = [required()];
    const validateEmail = [required(),email()];
    const validateZipCode = [required(),regex(/^\d{5}$/,translate('resources.users.errors.zip'))];
    const validateFirstName = [required(), minLength(2), maxLength(15)];
    const validateCity = [required(), minLength(2), maxLength(15)];
    const validatePassword = [required(), minLength(8)];
    const phoneValidate = [required(),regex(/\(?\+\(?49\)?[ ()]?([- ()]?\d[- ()]?){6}/im,translate('resources.users.errors.phone'))];
    const validateStreet = [required(), minLength(2), maxLength(30)];
    const { basePath, ...rest } = props;
    const { permissions } = usePermissions();
    const dispatch = useDispatch();

    const getImage = (record) => ({
        type: "GET_CLICKED_COURSE",
        payload: record,
    });

    const onDrop = (picture) =>{
        console.log(picture);
        // dispatch(getImage(picture[0]));
        props['data'].file=picture;
        // localStorage.setItem('image',picture[0]);
    };
       /* this.setState({
            pictures: this.state.pictures.concat(picture),
        });*/

    return (
        <Edit
            {...props}
            title={<UserTitle />}
            hasShow={false}
        >
            <SimpleForm toolbar={<EditActions />} redirect="show" validate={validatePasswords}>
                {/*<Card style={{width:'100%'}}>
                        <CardContent>*/}
                        <Box style={{width:'100%'}} display={{ md: 'block', lg: 'flex' }}>
                            <Box flex={2} mr={{ md: 0, lg: '1em' }}>
                            <Typography variant="h6" gutterBottom>
                                {translate(
                                    'resources.users.fieldGroups.identity'
                                )}
                            </Typography>
                            <Box display={{ xs: 'block', sm: 'flex' }}>
                                {/*<Box
                                    flex={1}
                                    mr={{ xs: 0, sm: '0.5em' }}
                                >
                                    <ImageUploader
                                        withIcon={true}
                                        buttonText='Choose images'
                                        onChange={onDrop}
                                        imgExtension={['.jpg', '.gif', '.png', '.gif']}
                                        maxFileSize={5242880}
                                        label="---Max file size: 5mb, accepted: jpg"
                                    />
                                </Box>*/}
                                <Box
                                    flex={1}
                                    mr={{ xs: 0, sm: '0.5em' }}
                                >
                                    <TextInput
                                        source="firstName"
                                        resource="users"
                                        validate={validateFirstName}
                                        fullWidth
                                    />
                                </Box>
                                <Box
                                    flex={1}
                                    ml={{ xs: 0, sm: '0.5em' }}
                                >
                                    <TextInput
                                        source="lastName"
                                        resource="users"
                                        validate={validateFirstName}
                                        fullWidth
                                    />
                                </Box>
                            </Box>
                            <Box display={{ xs: 'block', sm: 'flex' }}>
                                <Box
                                    flex={1}
                                    mr={{ xs: 0, sm: '0.5em' }}
                                >
                                    <TextInput
                                        type="email"
                                        source="email"
                                        resource="users"
                                        validate={validateEmail}
                                        fullWidth
                                    />
                                </Box>
                                <Box
                                    flex={1}
                                    ml={{ xs: 0, sm: '0.5em' }}
                                >
                                    <DateInput
                                        source="birthday"
                                        validate={requiredValidate}
                                        resource="users"
                                        fullWidth
                                    />

                                </Box>
                            </Box>
                            <Box display={{ xs: 'block', sm: 'flex' }}>
                                <Box
                                    flex={1}
                                    mr={{ xs: 0, sm: '0.5em' }}
                                >
                                    <TextInput type="tel" source="phone" resource="users" fullWidth validate={phoneValidate}/>
                                </Box>
                                <Box
                                    flex={1}
                                    ml={{ xs: 0, sm: '0.5em' }}
                                >
                                    <SelectInput source="gender" validate={requiredValidate} resource="users" fullWidth choices={[
                                        { id: 0, name: 'resources.users.fields.genders.male'},
                                        { id: 1, name: 'resources.users.fields.genders.female' },
                                        { id: 2, name: 'resources.users.fields.genders.other' },
                                    ]} />
                                </Box>
                            </Box>
                            <RadioButtonGroupInput source="notificationOption" resource="users" validate={requiredValidate} choices={[
                                { id: 2, name: 'resources.users.fields.notification.emailPlatform' },
                                { id: 1, name:  'resources.users.fields.notification.platform' }
                            ]} />
                            <Box mt="1em" />

                            <Typography variant="h6" gutterBottom>
                                {translate('resources.users.fieldGroups.address')}
                            </Typography>
                            <TextInput
                                source="street"
                                resource="users"
                                multiline
                                fullWidth
                                validate={validateStreet}
                            />
                            <Box display={{ xs: 'block', sm: 'flex' }}>
                                <Box
                                    flex={1}
                                    mr={{ xs: 0, sm: '0.5em' }}
                                >
                                    <TextInput
                                        source="zip"
                                        resource="users"
                                        fullWidth
                                        validate={requiredValidate}
                                    />
                                </Box>
                                <Box
                                    flex={2}
                                    ml={{ xs: 0, sm: '0.5em' }}
                                >
                                    <TextInput
                                        source="city"
                                        resource="users"
                                        fullWidth
                                        validate={validateCity}
                                    />
                                </Box>
                            </Box>

                            <Box mt="1em" />

                            <Typography variant="h6" gutterBottom>
                                {translate(
                                    'resources.users.fieldGroups.change_password'
                                )}
                            </Typography>
                            <Box display={{ xs: 'block', sm: 'flex' }}>
                                <Box
                                    flex={1}
                                    mr={{ xs: 0, sm: '0.5em' }}
                                >
                                    <PasswordInput
                                        source="plainPassword"
                                        resource="users"
                                        autoComplete="new-password"
                                        fullWidth
                                    />
                                </Box>
                                <Box
                                    flex={1}
                                    ml={{ xs: 0, sm: '0.5em' }}
                                >
                                    <PasswordInput
                                        source="confirm_password"
                                        resource="users"
                                        fullWidth
                                    />
                                </Box>
                            </Box>
                        </Box>

                        </Box>
                       {/* </CardContent>
                </Card>*/}
            </SimpleForm>
        </Edit>
    );
};

const UserTitle = ({ record }) => record ? <FullNameField record={record} size="32" /> : null;

export default UserEdit;
