import {
    SimpleForm,
    BooleanInput,
    Edit, TextInput, Toolbar, SaveButton,
} from 'react-admin';

import React from "react";
import {Redirect} from "react-router-dom";

export  const WeekCreate = () => <Redirect to="/your_courses" />;
export const WeekList = ()=> <Redirect to="/your_courses" />;
export const WeekShow = ()=> <Redirect to="/your_courses" />;
const redirect = (basePath, id, data) => `/curricula/${encodeURIComponent(data.curriculum)}/show`;
const PostEditToolbar = props => (
    <Toolbar {...props} >
        <SaveButton />
    </Toolbar>
);
export const WeekEdit= props => (
    <Edit {...props} hasShow={false}>
        <SimpleForm  redirect={redirect} toolbar={<PostEditToolbar />}>
            <TextInput source="Notice" label={"resources.curricula.fields.Notice"} fullWidth/>
            <BooleanInput source={"done"} label={"resources.curricula.fields.done"} />
        </SimpleForm>
    </Edit>
);