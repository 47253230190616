import englishMessages from 'ra-language-english';
import germanMessages from "ra-language-german";

const  users= {
    name: 'User |||| Users',
    fields: {
        address: "Address",
        gender: 'Gender',
        name:"Name",
        genders:{
            male: 'Male',
            female: 'Female',
            other: 'Other',
        },
        email:'Email',
        phone:"Phone",
        notification:{
            emailPlatform:'Learning platform + Email',
            platform:'Learning platform',
        },
        notificationOption:'Notification options',
        confirm_password: 'Confirm Password',
    },
    fieldGroups: {
        identity: 'Identity',
        address: 'Address',
        group: 'Group',
        password: 'Password',
        change_password: 'Change Password',
        status: 'Active'
    },
    page: {
        delete: 'Delete Customer',
    },
    errors: {
        password_mismatch:'The password confirmation is not the same as the password.',
        zip:'Must be a valid zip code',
        phone:'Must be a valid phone',
        emailExist: 'This Email is already used',
        password_length:'Password must be 8 characters',
        password_confirm_length:'Password confirmation must be 8 characters',
        token:'Token is expired',
    },
};

export default {
    ...englishMessages,
    pos: {
        search: 'Search',
        configuration: 'Parameter',
        profile: 'My Profile',
        language: 'Language',
        theme: {
            name: 'Theme',
            light: 'Light',
            dark: 'Dark',
        },
        dashboard: {
            name: "Dashboard"
        },
        menu: {
            users: 'Profile',
            menuCourseOrganisation: 'Course-Organisation',
        },
        forgetPassword:{
            successMsg:'Email has been sent',
            link:'Forget password?'
        },
        resetPassword:{
            successMsg:'Password has been successfully changed'
        }
    },
    resources: {
        your_courses:{
            name:'Your Course |||| Your Course '
        },
        courses: {
            name: 'Course Catalog |||| Course Catalog',
            currency:'USD',
            fields: {
                name:'Name',
                level: "Level",
                beginDate: "BeginDate",
                endDate: "EndDate",
                hourCount: "HourCount",
                status : "Status",
                booked: "Booked",
                book: "Book",
                price  : "Price ",
                completed: "Completed",
                accessUntil:"Access Until",
                expired:"Expired",
                sendFeedbackBtn:'Send'
            },
            tabs:{
                label:"Course",
                curriculum:"Curriculum",
                homeWorks:"HomeWorks",
            }
        },
        users:{...users},

        notifications: {
            name: 'Notification |||| Notifications',
            fields: {
                createdAt: 'CreatedAt',
                name : 'Subject',
                content: 'Content',
                senderName: 'Sender'
            },
        },
        customers: {
            name: 'Customers |||| Customers',
            fields: {...users.fields},
            fieldGroups: {...users.fieldGroups},
            page:{...users.page},
            errors:{...users.errors},
            tabs:{
                infoPersonnel:"Personal information"
            }
        },
        bookings: {
            name: 'Accounting |||| Accounting',
            fields: {
                accessUntil:'Access Until',
                invoiceNbr: 'InvoiceNbr'
            },
            statuses:{
                'Bestätigt':'Confirmed',
                'Unbestätigt':'Unconfirmed',
                'Storniert':'Canceled',
                'Sperren':'Lock',
            },
            invoiceBtn:'Invoice',
            invoiceStornoBtn:'Cancellation Invoice',
        },
        home_works: {
            name: 'Homework |||| Homework',
            fields: {
                description:'Description',
                type:'Type',
            },
            type:{
                write:"Writing task",
                speak:"speaking task",
            }
        },

        homeWorks: {
            name: 'homeWorks |||| homeWorks',
            fields: {
                description: 'description',
                type: 'type',
            },
        },
        curricula: {
            name: 'Curricula ',
            config_name: 'set up Curriculum',
            fields: {
                name :" name",
                done : 'done',
                learningDays: 'learningDays',
                sessions:'sessions',
                Notice: 'Notice',
                notices:'notices',
                deadline :'deadline:',
                isnotify:'Notification (receive reminders when you should study',
                minutCount: 'How long do you want to lean (minutes):',
                weekDays: 'What days of the week do you want to study?'
            },
            days:{
                mo :'Mo.',
                tu:'Tu.',
                we:'We.',
                th:'Th.',
                fr:'Fr.',
                sa:'Sa.',
                su:'Su.'
            }
        },
        auth:{
            ...germanMessages.ra.auth,
            forget_password: 'Passwort zurücksetzen'
        }
    },

    ra: {
        ...englishMessages.ra,
        page :{
            ...englishMessages.ra.page,
            loading: "Loading",
            invite : "Invite",
            empty : "Empty"
        },
        auth:{
            ...englishMessages.ra.auth,
            forget_password: 'Forget Password',
            reset_password: 'Reset Password',
            false_login : 'Username or Password is incorrect',
            inactive_account : 'Your Account is Disabled',
            access_unauthorized: 'Access unauthorized',
            not_found_email : 'E-Mail not found',
            email_validate : 'Must be a valid email',
            auth_check_error:'Please login to continue'
        }

    }
};
