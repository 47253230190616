import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import withWidth from '@material-ui/core/withWidth';
import {DateField, ShowButton, TextField, useTranslate} from 'react-admin';
import {Card, CardActions, CardContent, CardHeader} from "@material-ui/core";

const useStyles = makeStyles(theme => ({
    gridList: {
        width: '100%',
        margin: 0,
    },
    placeholder: {
        backgroundColor: theme.palette.grey[300],
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    widthFormGroup: { display: 'inline-block' },
    heightFormGroup: { display: 'inline-block', marginLeft: 32 },
    button: {
        marginTop: "1em",
        marginBottom: "1em",
    },
    root: {
        margin: '1em',
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap'
    },
    card: {
        // height: '100%',
        width: 300,
        display: 'flex',
        flexDirection: 'column',
        margin: '0.5rem 0.5rem',
    },
    cardTitleContent: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    cardContent: {
        ...theme.typography.body1,
        display: 'flex',
        flexDirection: 'column',
    }
}));

const getColsForWidth = width => {
    if (width === 'xs') return 2;
    if (width === 'sm') return 3;
    if (width === 'md') return 4;
    if (width === 'lg') return 5;
    return 6;
};

const times = (nbChildren, fn) =>
    Array.from({ length: nbChildren }, (_, key) => fn(key));

const LoadingGridList = ({ width, nbItems = 10 }) => {
    const classes = useStyles();
    return (
        <div className={classes.root}>
                {' '}
                {times(nbItems, key => (
                    <Card key={key} className={classes.card}>
                        <CardHeader className={classes.cardTitleContent} />
                        <CardContent className={classes.cardContent} />
                    </Card>
                ))}
        </div>
    );
};

const LoadedGridList =  ({ ids, data, basePath }) => {
    const classes = useStyles();
    const translate = useTranslate();
    return (
            <div className={classes.root}>
                {ids.map(id => (
                    <Card key={id} className={classes.card}>
                        <CardHeader
                            title={
                                <div className={classes.cardTitleContent}>
                                    <h5>{`${data[id].name}`}</h5>
                                </div>
                            }
                        />
                        <CardContent className={classes.cardContent}>
                            <div>
                                {translate(
                                    'resources.courses.fields.level'
                                )}
                                &nbsp;:&nbsp;
                                <TextField
                                    source="level"
                                     record={data[id]}
                                    label="level"
                                />
                            </div>
                            {/*<div>
                                {translate(
                                    'resources.courses.fields.beginDate'
                                )}
                                &nbsp;:&nbsp;
                                <DateField  record={data[id]} source={"beginDate"} />
                            </div>*/}
                            {/*<div>
                                {translate(
                                    'resources.courses.fields.endDate'
                                )}
                                &nbsp;:&nbsp;
                                <DateField  record={data[id]} source={"endDate"} />
                            </div>*/}
                            <div style={{fontWeight:"bold"}}>
                                {translate(
                                    'resources.courses.fields.accessUntil'
                                )}
                                &nbsp;:&nbsp;
                                <DateField style={{fontWeight:"bold"}} record={data[id]}
                                           source={"accessUntil"}
                                           options={{year: 'numeric', month: 'numeric', day: 'numeric' }}
                                           showTime
                                />
                            </div>
                            <div>
                                {translate(
                                    'resources.courses.fields.hourCount'
                                )}
                                &nbsp;:&nbsp;
                                <TextField  record={data[id]} source={"hourCount"} />
                            </div>
                            <div style={{fontWeight:"bold"}}>
                                {translate(
                                    'resources.courses.fields.completed'
                                )}
                                &nbsp;:&nbsp;
                                <TextField  style={{fontWeight:"bold"}} record={data[id]} source={"percent"} />
                                <span  style={{fontWeight:"bold"}} >%</span>
                            </div>
                            {/*<div>
                                {translate(
                                    'resources.courses.fields.status'
                                )}
                                &nbsp;:&nbsp;
                                <TextField  record={data[id]} source={"status"} />
                            </div>*/}

                        </CardContent>

                        <CardActions style={{ textAlign: 'right' }}>
                            <ShowButton basePath={basePath}  record={data[id]} />
                        </CardActions>
                    </Card>
                ))}
        </div>
    );
};

const GridList = ({ loaded, ...props }) =>
    loaded ? <LoadedGridList {...props} /> : <LoadingGridList {...props} />;

export default withWidth()(GridList);