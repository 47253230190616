import CourseIcon from '@material-ui/icons/Book';
import YourCourseList from './YourCourseList';
import YourCourseShow from './YourCourseShow';
import CourseEdit from "./CourseEdit";

export default {
    list: YourCourseList,
    show: YourCourseShow,
    edit: CourseEdit,
    icon: CourseIcon,
};