import React, {useState} from "react";
import { Redirect } from 'react-router';
import {
    TextField,
    RichTextField, required,
    SimpleForm,
    Edit,
    Toolbar,
    SaveButton, Loading
} from 'react-admin';
import RichTextInput from 'ra-input-rich-text';
import { ReactMic } from 'react-mic';
import MicIcon from '@material-ui/icons/Mic';
import StopIcon from '@material-ui/icons/Stop';
import IconButton from '@material-ui/core/IconButton';


const requiredValidate = [required()];
const HomeWorkTitle = ({ record }) => <span>{record.name}</span>;

export  const HomeWorkCreate = () => <Redirect to="/" />;
export  const HomeWorkShow = () => <Redirect to="/" />;
export  const HomeWorksList = () => <Redirect to="/" />;

const CustomAnswer = ({record})=>{
    const [recording,setRecording] = useState(false);
    const [loading, setLoading] = useState(false);
    const [blobURL,setBlobURL] = useState('');
    const onData =()=> {
        //console.log('This function does not return an object, but is called at a time interval of 10ms');
    };

    const onStop= (recordedBlob)=> {
        setLoading(true);
        setBlobURL(recordedBlob.blobURL);
        const file = new File([recordedBlob.blob], "record.mp3");
        const formData = new FormData();
        formData.append("audio", file, "audio.mp3");
        formData.append("token", window.localStorage.getItem('token'));
        const xhr = new XMLHttpRequest();
        // const url = process.env.REACT_APP_UPLOAD_ENTRYPOINT;
        const url = process.env.REACT_APP_BACKEND_URL+'/upload_audio';
        xhr.open('POST', url, true);
        xhr.onload = function () {
            setLoading(false);
            if (xhr.status === 200) {
                console.log("file uploaded");
            } else {
                console.log('An error occurred!');
            }
        };
        xhr.send(formData);
    };
    const startRecording = () => {
        setRecording(true);
    };

    const stopRecording = () => {
        setRecording(false);
    };
    if (loading) return <Loading />;
    return(
        <div>
        { record.type === "write" ?
            <RichTextInput source={"writingAntwort"} validate={requiredValidate}  />
        :
            <div>
                <ReactMic
                    record={recording}
                    className="sound-wave"
                    onStop={onStop}
                    onData={onData}
                    strokeColor="#000000"
                    backgroundColor="#d8d8d8"
                    mimeType="audio/mp3" /><br/>
                {!recording ?
                    <IconButton color="primary"
                                aria-label="start"
                                component="div"
                                variant="contained"
                                onClick={startRecording} >
                        <MicIcon />
                    </IconButton>
                :
                <IconButton color="primary"
                            aria-label="stop"
                            component="div"
                            onClick={stopRecording} >
                    <StopIcon />
                </IconButton>
                }
                <br/>
                {blobURL !=="" &&
                    <audio src={blobURL} controls="controls"/>
                }
            </div>
        }
        </div>
    )
};
const PostEditToolbar = props => (
    <Toolbar {...props} >
        <SaveButton />
    </Toolbar>
);
export  const HomeWorkEdit= props => (
        <Edit {...props} title={<HomeWorkTitle/>} hasShow={false} >
            <SimpleForm toolbar={<PostEditToolbar />} redirect={'/your_courses'}>
                <TextField source={"name"}  />
                <RichTextField  source={"description"} />
                <CustomAnswer />
            </SimpleForm>
        </Edit>
);

