import React, {forwardRef, useState,useEffect,useCallback } from 'react';
import {AppBar, UserMenu, MenuItemLink, useTranslate, useDataProvider,useQueryWithStore,useVersion} from 'react-admin';
import Typography from '@material-ui/core/Typography';
import SettingsIcon from '@material-ui/icons/Settings';
import ProfileIcon from '@material-ui/icons/People';
import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';

import Logo from './Logo';
import Badge from "@material-ui/core/Badge";
import NotificationsIcon from "@material-ui/icons/Notifications";
import HelpIcon from '@material-ui/icons/Help';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import {Box} from '@material-ui/core';
import Moment from 'moment';
import { useHistory } from 'react-router-dom';
import {useDispatch, useSelector} from "react-redux";

const useStyles = makeStyles({
    title: {
        flex: 1,
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
    },
    spacer: {
        flex: 1,
    },
    boxNotRead:{
        backgroundColor:'#8080807a'
    },
    box:{
        backgroundColor:'White'
    },
    span :{
        display: "inline-block",
        width: "180px",
        whiteSpace: 'nowrap',
        overflow: 'hidden !important',
        textOverflow: 'ellipsis'
    }
});


const ConfigurationMenu = forwardRef((props, ref) => {
    const translate = useTranslate();
    return (
        <MenuItemLink
            ref={ref}
            to="/configuration"
            primaryText={translate('pos.configuration')}
            leftIcon={<SettingsIcon />}
            onClick={props.onClick}
        />
    );
});
const ProfileMenu = forwardRef((props, ref) => {
    const translate = useTranslate();
    const user = JSON.parse(localStorage.getItem('user'));
    return (
        <MenuItemLink
            ref={ref}
            to={`/${user.role}/%2Fapi%2F${user.role}%2F${user.id}/show`}
            primaryText={translate('pos.profile')}
            leftIcon={<ProfileIcon />}
            onClick={props.onClick}
        />
    );
});

const CustomUserMenu = (props) => {
    return (
        <UserMenu {...props}>
            <ProfileMenu />
            <ConfigurationMenu />
        </UserMenu>
    )};

const changePermission = (permissions) => ({
    type: 'CHANGE_PERMISSIONS',
    payload: permissions,
});

const CustomAppBar = (props) => {
    const classes = useStyles();
    const history = useHistory();
    const dataProvider = useDataProvider();
    const dispatch = useDispatch();


    const user = localStorage.getItem('user');
    const currentUser = (user) ? JSON.parse(user):null;
    const [notifications,setNotifications]= useState([]);

    const [auth, setAuth] = React.useState(true);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);

    const handleChange = (event) => {
        setAuth(event.target.checked);
    };

    const handleMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const version = useVersion();
    const fetchNotifications = useCallback(async () => {
        if( window.localStorage.getItem("token")){
            const resp = await dataProvider.getList('notifications', {
                pagination: { page: 1 , perPage: 100000000000 },
                sort: { field: 'createdAt', order: 'DESC' },
            });
            if(resp)
                setNotifications(resp.data);
        }

        /*const url = new URL(process.env.REACT_APP_MERCURE_PUBLISH_URL);
        url.searchParams.append('topic', `${process.env.REACT_APP_BACKEND_URL}/api/notifications/{id}`);
        const eventSource = new EventSource(url);
        eventSource.onmessage = e =>{
            let n= JSON.parse(e.data);
            if(n.user === '/api/customers/'+currentUser.id){
                let obj ={
                    '@id': n['@id'],
                    '@type': n['@type'],
                    content: n.content,
                    createdAt: n.createdAt,
                    id: n['@id'],
                    irId: n.irId,
                    isRead: n.isRead,
                    modul: n.modul,
                    name: n.name,
                    originId: n.id,
                    user: n.user
                };
                setNotifications(notifications => [obj,...notifications]);
            }

        };*/
    }, [dataProvider]);

    const fetchPermissions = useCallback(async () => {
        if( window.localStorage.getItem("token")) {
            const response = await dataProvider.getList('permissions', {
                pagination: {page: 1, perPage: 100000000000},
                sort: {field: 'name', order: 'ASC'},
            });
            if (response)
                dispatch(changePermission(response.data));
        }

    }, [dataProvider]);


    useEffect(() => {
        fetchNotifications();
        fetchPermissions();
    }, [version]);

    return (
        <AppBar {...props} userMenu={<CustomUserMenu />}>
            <Typography
                variant="h6"
                color="inherit"
                className={classes.title}
                id="react-admin-title"
            />
            <Logo />
            <span className={classes.spacer} />
            <div>
                <IconButton aria-label="Help/Support"
                            color="inherit"
                            target="_blank" href="#"
                >
                    <HelpIcon />
                </IconButton>
            </div>
            <div>
                <IconButton aria-label="show new notifications" color="inherit"
                            onClick={handleMenu}
                            aria-controls="menu-appbar"
                            aria-haspopup="true">
                    <Badge badgeContent={notifications.filter(val => {return val.isRead === false}).length} color='primary'>
                        <NotificationsIcon />
                    </Badge>
                </IconButton>
                <Menu
                    id="menu-appbar"
                    anchorEl={anchorEl}
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    keepMounted
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    open={open}
                    onClose={handleClose}
                >
                    {notifications && notifications.map((notification,index) => (
                        <MenuItem key={index}
                                  onClick={handleClose}
                                  className={!notification.isRead ? classes.boxNotRead: classes.box}
                                  style={{borderBottom:'1px solid #00000033',marginBottom:'2px'}}
                        >
                            <Box component="div"
                                 style={{maxWidth:'200px' }}
                                 onClick={()=> {
                                     if (!notification.isRead){
                                         dataProvider.update('notifications', {
                                             id:  notification.id,
                                             data: {isRead: true}
                                         }).then(() => {
                                             const not = notifications.slice();
                                             notification.isRead=true;
                                             not[index] = notification;
                                             setNotifications(not);
                                         });
                                     }
                                     let path = `/${notification.modul}/${encodeURIComponent(notification.irId)}`;
                                     if(notification.modul === 'your_courses')
                                        path += "/show";
                                     if(notification.modul === 'notifications'){
                                         path = `/${notification.modul}/${encodeURIComponent(notification.id)}/show`;
                                     }

                                     history.push(path);
                                 }}

                            >
                                <Box component="p" m={1} color="textSecondary" >
                                    {Moment(notification.createdAt).format('DD.MM.YYYY')}
                                </Box>
                                <Box component="p" m={1} color="textSecondary" >
                                    <span className={classes.span}>{notification.name}</span>
                                </Box>
                                <Box component="p" m={1}>
                                    <span className={classes.span}>{notification.content.replace(/(<([^>]+)>)/ig,"")}</span>
                                </Box>
                            </Box>
                        </MenuItem>
                    ))}
                </Menu>
            </div>
        </AppBar>
    );
};

export default CustomAppBar;
