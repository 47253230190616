import React, {useCallback, useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import { useMediaQuery } from '@material-ui/core';
import {useTranslate, DashboardMenuItem, MenuItemLink, useDataProvider, useVersion} from 'react-admin';

import courses from '../components/courses';
import bookings from '../components/bookings';


const changePermission = (permissions) => ({
    type: 'CHANGE_PERMISSIONS',
    payload: permissions,
});
const Menu= ({ onMenuClick, dense }) => {
    const [state, setState] = useState({
        menuUsers: true,
        menuCourseOrganisation: true,
    });
    const translate = useTranslate();
    const isXSmall = useMediaQuery((theme) =>
        theme.breakpoints.down('xs')
    );
    const open = useSelector((state) => state.admin.ui.sidebarOpen);
    useSelector((state) => state.theme);

    const handleToggle = (menu) => {
        setState(state => ({ ...state, [menu]: !state[menu] }));
    };
    const dataProvider = useDataProvider();
    const dispatch = useDispatch();

    const getPermissions = ()=>{
            if( window.localStorage.getItem("token")){
                dataProvider.getList('permissions', {
                    pagination: { page: 1 , perPage: 100000000000 },
                    sort: { field: 'name', order: 'ASC' },
                }).then(({data})=>{
                    dispatch(changePermission(data));
                });
            }

    };

    const version = useVersion();
    const fetchPermissions = useCallback(async () => {
        if( window.localStorage.getItem("token")) {
            const response = await dataProvider.getList('permissions', {
                pagination: {page: 1, perPage: 100000000000},
                sort: {field: 'name', order: 'ASC'},
            });
            if (response)
                dispatch(changePermission(response.data));
        }


    }, [dataProvider]);
    useEffect(() => {
        fetchPermissions();
    }, [version]);

    // const permissions = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')).permissions : [];
    const permissions = useSelector((state) => state.permissions);

    return (
        <div>
            {' '}
            { permissions.find(function(element) {return element.module === 'Dashboard'}) &&

                <DashboardMenuItem onClick={onMenuClick} onMouseDown={getPermissions} sidebarIsOpen={open} primaryText={translate(`pos.dashboard.name`, {smart_count: 2,})} />
            }
            {permissions.find(function (element) {
                return element.module === 'Course'
            }) &&
                <MenuItemLink
                    to={`/your_courses`}
                    primaryText={translate(`resources.your_courses.name`, {
                        smart_count: 2,
                    })}
                    leftIcon={<courses.icon />}
                    onClick={onMenuClick} onMouseDown={getPermissions}
                    sidebarIsOpen={open}
                    dense={dense}
                />
            }
            {permissions.find(function (element) {
                return element.module === 'Course'
            }) &&
                <MenuItemLink
                    to={`/courses`}
                    primaryText={translate(`resources.courses.name`, {
                        smart_count: 2,
                    })}
                    leftIcon={<courses.icon />}
                    onClick={onMenuClick} onMouseDown={getPermissions}
                    sidebarIsOpen={open}
                    dense={dense}
                />
            }
            {permissions.find(function (element) {
                return element.module === 'Booking' && element.permission==='READ'
            }) &&
                <MenuItemLink
                    to={`/bookings`}
                    primaryText={translate(`resources.bookings.name`, {
                        smart_count: 2,
                    })}
                    leftIcon={<bookings.icon />}
                    onClick={onMenuClick} onMouseDown={getPermissions}
                    sidebarIsOpen={open}
                    dense={dense}
                />
            }
        </div>
    );
};

export default Menu;
