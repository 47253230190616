import germanMessages from 'ra-language-german';
const  users= {
    name: 'User |||| Users',
    fields: {
        name:"Name",
        email:'Email',
        firstName: 'Vorname',
        lastName: 'Nachname',
        gender: 'Geschlecht',
        birthday: 'Geburstag',
        street: 'Straße',
        zip: 'PlZ',
        city: 'Stadt',
        phone: 'Telefonnummer',
        avatar: 'Bild',
        groups: 'Gruppe',
        active: 'Aktiv',
        genders:{
            male: 'Männlich',
            female: 'Weiblich',
            other: 'Andere',
        },
        address: "Adresse",
        plainPassword: 'Neu Passwort',
        confirm_password: 'Kennwort bestätigen',
        notification:{
            emailPlatform:'Lernplattform + Email',
            platform:'Lernplattform',
        },
        notificationOption:'Benachrichtigungsoptionen'

    },
    fieldGroups: {
        identity: 'Identität',
        address: 'Adresse',
        group: 'Gruppe',
        zip: 'Plz',
        city: 'Stadt',
        phone: 'Telefonnummer',
        avatar: 'Bild',
        password: 'Passwort',
        change_password: 'Passwort ändern',
        status: "Aktiv"
    },
    page: {
        delete: 'Delete Users',
    },
    errors: {
        password_mismatch:'Die Passwortbestätigung stimmt nicht mit dem Passwort überein.',
        zip:'Muss eine gültige Postleitzahl sein',
        phone:'Muss eine gültige Telefonnummer sein',
        emailExist: 'Diese E-Mail wird bereits verwendet',
        password_length:'Passwort muss 8 Character sein',
        password_confirm_length:'Passwortbestätigung muss 8 Character sein',
        token:'Token ist abgelaufen',
    }
};

export default {
    ...germanMessages,
    pos: {
        search: 'Suchen',
        configuration: 'Einstellungen',
        profile: 'Mein Profil',
        language: 'Sprache',
        theme: {
            name: 'Theme',
            light: 'Light',
            dark: 'Dark',
        },
        dashboard: {
            name: "Startseite"
        },
        menu: {
            users: 'Profile',
            menuCourseOrganisation: 'Kurs-Organisation',
        },
        inactiveAccount :'inaktives Benutzerkonto',
        forgetPassword:{
            successMsg:'Email wurde geschickt',
            link:'Passwort vergessen?'
        },
        resetPassword:{
            successMsg:'Passwort wurde erfolgreich geändert'
        }

    },
    resources: {
        your_courses:{
            name: 'Deine Kurse |||| Deine Kurse',
            fields: {
                name:"Name",
                level: "Stufe",
                beginDate: "Anfang Datum",
                endDate: "End Datum",
                hourCount: "Stundenanzahl",
                price : "Preis",
                documents: "Dokumente",
                sessions:"Lektionen",
                teacher: "Lehrkraft",
                status: "Status",
                booked: "Gebucht",
                book: "Buchen",
                completed: "Abgeschlossen",
                accessUntil:"Zugriff bis",
                expired:"Abgelaufen",
                sendFeedbackBtn:'Absenden'
            },
        },
        courses: {
            name: 'Kurskatalog |||| Kurskatalog',
            currency:'EUR',
            fields: {
                name:"Name",
                level: "Stufe",
                beginDate: "Anfang Datum",
                endDate: "End Datum",
                hourCount: "Stundenanzahl",
                price : "Preis",
                documents: "Dokumente",
                sessions:"Lektionen",
                teacher: "Lehrkraft",
                status: "Status",
                booked: "Gebucht",
                book: "Buchen",
                completed: "Abgeschlossen",
                accessUntil:"Zugriff bis",
                expired:"Abgelaufen",
                sendFeedbackBtn:'Absenden'
            },
            tabs:{
                label:"kurse",
                curriculum:"Lernplan",
                homeWorks:"Hausaufgabe",
            }
        },
        users:{...users},
        customers: {
            name: 'Kunde |||| Kunden',
            fields: {...users.fields},
            fieldGroups: {...users.fieldGroups},
            page:{...users.page},
            errors:{...users.errors},
            tabs:{
                infoPersonnel:"Persönliche information"
            }
        },
        bookings: {
            name: 'Rechnung |||| Rechnungen',
            fields: {
                course : "Kurse",
                payment: "Zahlungsart",
                paymentStatus: "Zahlungsstatus",
                bookingStatus : "Buchungsstatus",
                invoiceNbr: "RechnungsNr.",
                createdAt: "Erstellt am",
                updatedAt: "Aktualisiert am",
                accessUntil:'Zugriff bis',
                courseName:'Kursname',
            },
            statuses:{
                'Bestätigt':'Bestätigt',
                'Unbestätigt':'Unbestätigt',
                'Storniert':'Storniert',
                'Sperren':'Sperren',
            },
            invoiceBtn:'Rechnung',
            invoiceStornoBtn:'StornoRechnung',
        },
        notifications: {
            name: 'Benachrechtigung |||| Benachrechtigungen',
            fields: {
                createdAt: 'Erstellt am',
                name : 'Betreff',
                content: 'Inhalt',
                senderName: 'Absender'
            },
        },
        home_works: {
            name: 'Hausaufgabe |||| Hausaufgaben',
            fields: {
                name : "Name",
                description : "Inhalt",
                type: "Art",
                course: "Kurse",
            },
            type:{
                write:"Schreibaufgabe",
                speak:"Sprechaufgabe",
            }
        },
        permissions: {
            name: 'Permissions |||| Permission',
            fields: {
                invoiceNbr : "InvoiceNbr.",
            },
        },
        curricula: {
            name: 'Lehrplan ',
            config_name: 'Lehrplan Aufstellen',
            fields: {
                name :" Woche",
                done : 'Erledigt',
                learningDays: 'Lerntage in der Woche',
                sessions:'Lektionen',
                Notice: 'Notiz',
                notices:'Eigene Notizen',
                deadline :'Zieldatum entsprechen Lehrplan:',
                isnotify:'Benachrichtigung (Erinnerungen erhalten, wenn du lernen solltest',
                minutCount: 'Wie lange möchtest du lehrnen (Minuten):',
                weekDays: 'An welchen Wochentagen möchtest du lernen?'
            },
            days:{
                mo :'Mo.',
                tu:'Di.',
                we:'Mi.',
                th:'Do.',
                fr:'Fr.',
                sa:'Sa.',
                su:'So.'
            }
        },
    },

    ra: {
        ...germanMessages.ra,
        page :{
            ...germanMessages.ra.page,
            loading: "Wird geladen",
            invite : "Einladen",
            empty : "Leer"
        },
        input:{
            ...germanMessages.ra.input,
          password: {
              ...germanMessages.ra.input.password,
              toggle_hidden: "toggle_hidden"
          }
        },
        boolean:{
            ...germanMessages.ra.boolean,
            null:""
        },
        notification:{
            ...germanMessages.ra.notification,
            logged_out: "Abmelden"
        },
        auth:{
            ...germanMessages.ra.auth,
            forget_password: 'Passwort zurücksetzen',
            reset_password: 'Passwort ändern',
            false_login : 'Benutzername oder Passwort ist falsch',
            inactive_account : 'Ihr Konto ist deaktiviert',
            access_unauthorized : 'Zugriff nicht autorisiert',
            not_found_email : 'E-Mail nicht gefunden',
            email_validate : 'Muss eine gültige E-Mail sein',
            auth_check_error:'Bitte einloggen zum Fortfahren'
        }

    }
};
