import React,{useState} from "react";
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import {DateField, TextField, useTranslate,useDataProvider,Loading, Error,Title} from 'react-admin';
import {useSelector} from "react-redux";
import { PayPalButton } from 'react-paypal-button';
import {useHistory} from "react-router-dom";
import Modal from '@material-ui/core/Modal';
import { makeStyles } from '@material-ui/core/styles';
import Iframe from "react-iframe";
import Avatar from "@material-ui/core/Avatar";
// import { CreditCardInput, LiteCreditCardInput } from "react-native-credit-card-input";

 const rand = () => {
    return Math.round(Math.random() * 20) - 10;
}
const getModalStyle = ()=> {
    const top = 50 + rand();
    const left = 50 + rand();

    return {
        top: `${top}%`,
        left: `${left}%`,
        transform: `translate(-${top}%, -${left}%)`,
    };
};
const useStyles = makeStyles((theme) => ({
    paper: {
        position: 'absolute',
        width: 800,
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
    },
    img:{
        width:80,
        height:60,
        marginRight:8,
        cursor:'pointer',
    }
}));

const Shipping = () => {
    const classes = useStyles();
    const translate = useTranslate();
    const record = useSelector((state) => state.courseRecord);

    const [modalStyle] = useState(getModalStyle);
    const [open, setOpen] = useState(false);

    const [saferPayURL, setSaferPayURL] = useState('');
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState();
    const history = useHistory();
    const dataProvider = useDataProvider();
    const onSuccess = (payment) =>{
        if(payment.status === "COMPLETED"){
            const user = JSON.parse(localStorage.getItem('user'));
            setLoading(true);
            dataProvider.create('bookings', {
                data: {
                    course: record.id,
                    customer: "/api/customers/"+user.id,
                    payment: "/api/payments/1",
                    paymentStatus: "/api/payment_statuses/1",
                    bookingStatus: "/api/booking_statuses/1",
                }
            }).then(response =>{
                setLoading(false);
                // user.courseIds.push(parseInt(response.data.course.split("/")[3]));
                // localStorage.setItem('user',JSON.stringify(user));
                history.push('/your_courses');
            });

        }
    };
    if (loading) return <Loading />;
    if (error) return <Error />;

    const onError = (error) =>
        alert('Erroneous payment OR failed to load script!');
    const onCancel = (data) =>
        alert('Cancelled payment!');

    const paypalOptions = {
        clientId: process.env.REACT_APP_PAYPAL_CLIENT_ID_SANDBOX,
        intent: 'capture',
        currency: translate('resources.courses.currency')
    };

    const buttonStyles = {
        layout: 'vertical',
        shape: 'rect',
    };

    const onChange= form => console.log(form);


    const handleOpen = (e) => {
        e.preventDefault();
        const paymentMethod = e.target.title;
        let url="";
        switch (paymentMethod) {
            case "GIROPAY":
                // url = process.env.REACT_APP_PAYMENT_GIRO_PAY_ENTRYPOINT;
                url= process.env.REACT_APP_BACKEND_URL+'/giroPayPayment';
                break;
            case "PAYPAL":
                // url = process.env.REACT_APP_PAYMENT_PAYPAL_ENTRYPOINT;
                url = process.env.REACT_APP_BACKEND_URL+'/payPalPayment';
                break;
            default:
                // url= process.env.REACT_APP_PAYMENT_ENTRYPOINT;
                url= process.env.REACT_APP_BACKEND_URL+'/saferPayPayment';

        }

        setLoading(true);
        const user = localStorage.getItem('user');
        const current_user = user ? JSON.parse(user): null;
        let firstName = current_user.firstName;
        let lastName = current_user.lastName;
        let courseId = record.articulateId;
        let price = record.price;
        const formData = new FormData();
        formData.append("firstName",firstName);
        formData.append("lastName",lastName);
        formData.append("courseId",courseId);
        formData.append("userId",current_user.id);
        formData.append("price",price);
        formData.append("paymentMethod",paymentMethod);
        formData.append("token",window.localStorage.getItem('token'));

        const request = new Request(`${url}`, {
            method: 'POST',
            body: formData,
        });
        fetch(request)
            .then(response => {
                if (response.status < 200 || response.status >= 300) {
                    setLoading(false);
                    alert(response.statusText);
                }
                return response.json();
            })
            .then(( {body}) => {
                window.location.href = body.RedirectUrl;
                setTimeout(()=>{
                    setLoading(false);
                },1000);
            });
    };

    /*const handleClose = () => {
        setOpen(false);
    };

    const body = (
        // <div style={modalStyle} className={classes.paper}>
        <Iframe url= {saferPayURL}
                width="100%"
                height="100%"
                id="myId"
                display="initial"
                // position="relative"
        />
        // </div>
            /!*<div style={modalStyle} className={classes.paper}>
                <h2 id="simple-modal-title">Text in a modal</h2>
                <p id="simple-modal-description">
                    {/!*Duis mollis, est non commodo luctus, nisi erat porttitor ligula.*!/}
                    <Iframe url= {saferPayURL}
                            width="500px"
                            height="500px"
                            id="myId"
                            className="myClassname"
                            display="initial"
                            position="relative"
                    />
                </p>
            </div>*!/
    );*/

    return (
        <Card>
            <Title title={record.name} />
            <CardContent >
                <div
                    style={{margin:'12px'}}>
                    {translate(
                        'resources.courses.fields.level'
                    )}
                    &nbsp;:&nbsp;
                    <TextField
                        source="level"
                        record={record}
                        label="level"
                    />
                </div>
                <div style={{margin:'12px'}}>
                    {translate(
                        'resources.courses.fields.hourCount'
                    )}
                    &nbsp;:&nbsp;
                    <TextField  record={record} source={"hourCount"} />
                </div>
                <div style={{margin:'12px'}}>
                    {translate(
                        'resources.courses.fields.price'
                    )}
                    &nbsp;:&nbsp;
                    <TextField  record={record} source={"price"} style={{fontWeight:'bold'}}/>&nbsp;
                    <span style={{fontWeight:'bold',fontSize:'14px'}}>{translate('resources.courses.currency')}</span>
                </div>
                <div style={{margin:'12px'}}>

                {/*<PayPalButton
                    paypalOptions={paypalOptions}
                    buttonStyles={buttonStyles}
                    amount={record.price}
                    onPaymentSuccess={onSuccess}
                    onPaymentError={onError}
                    onPaymentCancel={onCancel}
                />*/}
                    <img
                        src="/media/payment_visa.png"
                        alt="VISA" title="VISA" className={classes.img} onClick={handleOpen}
                    />
                    {/*<img
                        src="https://www.saferpay.com/vt2/Areas/Mobile/Content/Images/CardLogos/CardType_21604.png?v=c672294f59"
                        alt="Maestro" title="MAESTRO"
                        className={classes.img} onClick={handleOpen}
                    />
                    <img
                        src="https://www.saferpay.com/vt2/Areas/Mobile/Content/Images/CardLogos/CardType_19269.png?v=b92a0a2111"
                        alt="MasterCard" title="MASTERCARD"
                        className={classes.img} onClick={handleOpen}
                    />
                    <img
                        src="https://www.saferpay.com/vt2/Areas/Mobile/Content/Images/CardLogos/CardType_19268.png?v=ed9292909b"
                        alt="Diners Club" title="DINERS"
                        className={classes.img} onClick={handleOpen}
                    />*/}
                    <img
                        src="/media/payment_giropay.png"
                        alt="Giro pay" title="GIROPAY"
                        className={classes.img} onClick={handleOpen}
                    />
                    <img
                        src="/media/payment_paypal.png"
                        alt="PayPal" title="PAYPAL"
                        className={classes.img} onClick={handleOpen}
                    />

                    {/*<button type="button" onClick={handleOpen}>
                        Open Modal
                    </button>*/}
                    {/*<Modal
                        open={open}
                        onClose={handleClose}
                        aria-labelledby="simple-modal-title"
                        aria-describedby="simple-modal-description"
                    >
                        {body}
                    </Modal>*/}
                </div>
            </CardContent>
        </Card>


    );
};

export default Shipping;
