import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import withWidth from '@material-ui/core/withWidth';
import {DateField, ShowButton, TextField, useTranslate,Button} from 'react-admin';
import {Card, CardActions, CardContent, CardHeader} from "@material-ui/core";
// import PaypalButton from "../payments/PaypalButton";
import { PayPalButton } from 'react-paypal-button'
import AddShoppingCartIcon from '@material-ui/icons/AddShoppingCart';
import { useHistory } from 'react-router-dom';
import {CHANGE_THEME, changeTheme} from "../../configuration/actions";
import {useDispatch} from "react-redux";

const useStyles = makeStyles(theme => ({
    widthFormGroup: { display: 'inline-block' },
    heightFormGroup: { display: 'inline-block', marginLeft: 32 },
    button: {
        marginTop: "1em",
        marginBottom: "1em",
    },
    root: {
        margin: '1em',
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap'
    },
    card: {
        // height: '100%',
        width: 300,
        display: 'flex',
        flexDirection: 'column',
        margin: '0.5rem 0.5rem',
    },
    cardGebucht: {
        // height: '100%',
        backgroundColor: '#8080807a',
        width: 300,
        display: 'flex',
        flexDirection: 'column',
        margin: '0.5rem 0.5rem',
    },
    cardTitleContent: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    cardContent: {
        ...theme.typography.body1,
        display: 'flex',
        flexDirection: 'column',
    },
}));

const getColsForWidth = width => {
    if (width === 'xs') return 2;
    if (width === 'sm') return 3;
    if (width === 'md') return 4;
    if (width === 'lg') return 5;
    return 6;
};

const times = (nbChildren, fn) =>
    Array.from({ length: nbChildren }, (_, key) => fn(key));

const LoadingGridList = ({ width, nbItems = 10 }) => {
    const classes = useStyles();
    return (
        <div className={classes.root}>
            {' '}
            {times(nbItems, key => (
                <Card key={key} className={classes.card}>
                    <CardHeader className={classes.cardTitleContent} />
                    <CardContent className={classes.cardContent} />
                </Card>
            ))}
        </div>
    );
};

/*const CLIENT = {
    sandbox: process.env.PAYPAL_CLIENT_ID_SANDBOX,
    production: process.env.PAYPAL_CLIENT_ID_PRODUCTION,
};
const ENV = process.env.REACT_APP_ENV === 'production'
    ? 'production'
    : 'sandbox';*/
const getClickedCourse = (record) => ({
    type: "GET_CLICKED_COURSE",
    payload: record,
});
const LoadedGridList =  ({ ids, data, basePath,resource }) => {
    const classes = useStyles();
    const translate = useTranslate();
    const courseIds = JSON.parse(localStorage.getItem('user')).courseIds;
    const history = useHistory();
    const dispatch = useDispatch();
    return (
            <div className={classes.root}>
                {ids.map(id => (
                    <Card key={id} className={data[id].accessUntil  ? classes.cardGebucht : classes.card}  >
                        <CardHeader
                            title={
                                <div className={classes.cardTitleContent} >
                                    <h5>{`${data[id].name}`}</h5>
                                </div>
                            }
                        />
                        <CardContent className={classes.cardContent}>
                            <div>
                                {translate(
                                    'resources.courses.fields.level'
                                )}
                                &nbsp;:&nbsp;
                                <TextField
                                    source="level"
                                     record={data[id]}
                                    label="level"
                                />
                            </div>
                            {/*<div>
                                {translate(
                                    'resources.courses.fields.beginDate'
                                )}
                                &nbsp;:&nbsp;
                                <DateField  record={data[id]} source={"beginDate"} />
                            </div>
                            <div>
                                {translate(
                                    'resources.courses.fields.endDate'
                                )}
                                &nbsp;:&nbsp;
                                <DateField  record={data[id]} source={"endDate"} />
                            </div>*/}
                            {data[id].accessUntil &&
                                <div>
                                    {new Date(data[id].accessUntil) - new Date() < 0 ?
                                        <span
                                            style={{fontWeight: 'bold'}}>{translate('resources.courses.fields.expired')}</span>
                                        :
                                        <div style={{fontWeight: "bold"}}>
                                            {translate(
                                                'resources.courses.fields.accessUntil'
                                            )}
                                            &nbsp;:&nbsp;

                                            <DateField style={{fontWeight: "bold"}} record={data[id]}
                                                       source={"accessUntil"}
                                                       options={{ year: 'numeric', month: 'numeric', day: 'numeric' }}
                                                       showTime
                                            />
                                        </div>
                                    }
                                </div>
                            }
                            <div>
                                {translate(
                                    'resources.courses.fields.hourCount'
                                )}
                                &nbsp;:&nbsp;
                                <TextField  record={data[id]} source={"hourCount"} />
                            </div>
                            {data[id].accessUntil ?
                                <div>
                                    {translate(
                                        'resources.courses.fields.status'
                                    )}
                                    &nbsp;:&nbsp;
                                    {/*<span style={{ fontWeight: 'bold' }} >{translate('resources.courses.fields.booked')}</span>*/}
                                    <span style={{ fontWeight: 'bold' }} >{translate('resources.bookings.statuses.'+data[id].bookingStatus)}</span>
                                    {/*<TextField  record={data[id]} source={"status"} />*/}
                                </div>
                                :
                                <div>
                                    {translate(
                                        'resources.courses.fields.price'
                                    )}
                                    &nbsp;:&nbsp;
                                    <TextField  record={data[id]} source={"price"} style={{ fontWeight: 'bold' }} />&nbsp;
                                    <span style={{ fontWeight: 'bold', fontSize:'14px' }} >{translate('resources.courses.currency')}</span>
                                </div>
                            }

                        </CardContent>

                        <CardActions style={{ textAlign: 'right',marginBottom:'0px' }}>
                            { data[id].accessUntil  === null  ?
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={()=> {
                                        let path = `/shipping`;
                                        history.push(path);
                                        dispatch(getClickedCourse(data[id]));
                                    }}
                                    label={translate('resources.courses.fields.book')}
                                    startIcon={<AddShoppingCartIcon/>}/>
                                // <ShowButton basePath={basePath}  record={data[id]} label={translate('resources.courses.fields.book')} />
                                :
                                null
                            }
                            {/*{data[id].status ==='gebucht' && <ShowButton basePath={basePath}  record={data[id]} label="Buchen" />}
                            {data[id].status !== 'gebucht'  &&
                            <div className={classes.cardTitleContent}>
                                <h5>{`${data[id].price} EUR`}</h5>
                                <PaypalButton
                                    client={CLIENT}
                                    env={ENV}
                                    commit={true}
                                    currency={'USD'}
                                    total={100}
                                    onSuccess={onSuccess}
                                    onError={onError}
                                    onCancel={onCancel}
                                />
                                <PayPalButton
                                    paypalOptions={paypalOptions}
                                    buttonStyles={buttonStyles}
                                    amount={1.00}
                                    onPaymentSuccess={onSuccess}
                                    onPaymentError={onError}
                                    onPaymentCancel={onCancel}
                                />
                            </div>
                            }*/}
                        </CardActions>
                    </Card>
                ))}
        </div>
    );
};

const GridList = ({ loaded, ...props }) =>
    loaded ? <LoadedGridList {...props} /> : <LoadingGridList {...props} />;

export default withWidth()(GridList);