import {Show, NumberField,SimpleShowLayout, DateField, TextField,TopToolbar,Button} from "react-admin";
import React,{useState} from "react";
import { PayPalButton } from 'react-paypal-button';
import {useHistory} from "react-router-dom";
import { useDataProvider,Loading, Error  } from 'react-admin';

const CourseTitle = ({ record }) => <span>{record.name}</span>;

const CustomPaypal = ({record }) => {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState();
    const history = useHistory();
    const dataProvider = useDataProvider();
    const onSuccess = (payment) =>{
        if(payment.status === "COMPLETED"){
            const user = JSON.parse(localStorage.getItem('user'));
            setLoading(true);
            dataProvider.create('bookings', {
                data: {
                    course: record.id,
                    customer: "/api/customers/"+user.id,
                    payment: "/api/payments/1",
                    paymentStatus: "/api/payment_statuses/1",
                }
            }).then(response =>{
                setLoading(false);
                user.courseIds.push(parseInt(response.data.course.split("/")[3]));
                localStorage.setItem('user',JSON.stringify(user));
                history.push('/your_courses');
            });

        }
    };
    if (loading) return <Loading />;
    if (error) return <Error />;

    const onError = (error) =>
        alert('Erroneous payment OR failed to load script!');
    const onCancel = (data) =>
        alert('Cancelled payment!');

    const paypalOptions = {
        clientId: 'AYEHH4CmLQRvFYDVJFGYzNKKX5H04mOsC21N8jXQGmVIfaB8r-ukaJIruLloYMPVrFHUYkVuzNibTn5B',
        intent: 'capture',
        currency: 'EUR'
    };

    const buttonStyles = {
        layout: 'vertical',
        shape: 'rect',
    };
    return (
        <PayPalButton
            paypalOptions={paypalOptions}
            buttonStyles={buttonStyles}
            amount={record.price}
            onPaymentSuccess={onSuccess}
            onPaymentError={onError}
            onPaymentCancel={onCancel}
        />
    )
};

/*const customAction = ()=>{
    console.log("clicked")
};
const CourseActions = ({ basePath, data, resource }) => (
    <TopToolbar>
        <Button color="primary" onClick={customAction}>Custom Action</Button>
    </TopToolbar>
);*/
 const CourseShow = (props) => {
     /*const [loading, setLoading] = useState(false);
     const [error, setError] = useState();
     const history = useHistory();
     const dataProvider = useDataProvider();
     const onSuccess = (payment) =>{
         if(payment.status === "COMPLETED"){
             const user = JSON.parse(localStorage.getItem('user'));
             setLoading(true);
             dataProvider.create('bookings', {
                 data: {
                     course: props.id,
                     customer: "/api/customers/"+user.id,
                     payment: "/api/payments/1",
                     paymentStatus: "/api/payment_statuses/1",
                 }
             }).then(response =>{
                     setLoading(false);
                     user.courseIds.push(parseInt(response.data.course.split("/")[3]));
                     localStorage.setItem('user',JSON.stringify(user));
                     history.push('/your_courses');
             });

         }
     };
     if (loading) return <Loading />;
     if (error) return <Error />;

     const onError = (error) =>
         alert('Erroneous payment OR failed to load script!');
     const onCancel = (data) =>
         alert('Cancelled payment!');

     const paypalOptions = {
         clientId: 'AYEHH4CmLQRvFYDVJFGYzNKKX5H04mOsC21N8jXQGmVIfaB8r-ukaJIruLloYMPVrFHUYkVuzNibTn5B',
         intent: 'capture',
         currency: 'EUR'
     };

     const buttonStyles = {
         layout: 'vertical',
         shape: 'rect',
     };*/
    return (
        <Show  {...props} title={<CourseTitle/>} hasEdit={false} >
            <SimpleShowLayout>
                <TextField source="name" />
                <TextField source="level" />
                <DateField source="beginDate" />
                <DateField source="endDate" />
                <NumberField source="hourCount" />
                <TextField  source={"price"} style={{ fontWeight: 'bold' }} />
                {/*<PayPalButton
                    paypalOptions={paypalOptions}
                    buttonStyles={buttonStyles}
                    amount={0.01}
                    onPaymentSuccess={onSuccess}
                    onPaymentError={onError}
                    onPaymentCancel={onCancel}

                />*/}
                <CustomPaypal/>
            </SimpleShowLayout>
        </Show>
    )
};

export default CourseShow;