import CourseIcon from '@material-ui/icons/Book';
import CourseList from './CourseList';
import CourseShow from './CourseShow';
import CourseEdit from "./CourseEdit";

export default {
    list: CourseList,
    show: CourseShow,
    edit: CourseEdit,
    icon: CourseIcon,
};