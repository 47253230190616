import React from 'react';
import {
    List, Filter, SearchInput,
    TextInput, DateInput,
    NumberInput, useTranslate
} from 'react-admin';
import GridList from './GridList';

const SearchFilter = props => {
    const translate = useTranslate();
    return (
    <Filter {...props}>
        <SearchInput source="name" alwaysOn placeholder={translate('resources.courses.fields.name')}/>
        <TextInput source="level" label={translate('resources.courses.fields.level')} />
        <DateInput  source="accessUntil" label={translate('resources.courses.fields.accessUntil')} />
        <NumberInput source="hourCount"  label={translate('resources.courses.fields.hourCount')}/>
    </Filter>
)};

const YourCourseList = props => {
    return (
        <List
            {...props}
            // perPage={20}
            // pagination={false}
            sort={{field: 'id', order: 'ASC'}}
            filters={<SearchFilter />}
            hasCreate={false}
            exporter={false}
        >
            <GridList />
        </List>
    )
};

export default YourCourseList;