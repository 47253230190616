import React from 'react';
import { Redirect } from 'react-router';

const styles = {
    flex: { display: 'flex' },
    flexColumn: { display: 'flex', flexDirection: 'column' },
    leftCol: { flex: 1, marginRight: '1em' },
    rightCol: { flex: 1, marginLeft: '1em' },
    singleCol: { marginTop: '2em', marginBottom: '2em' },
};
const Dashboard =() => {
    const user = JSON.parse(localStorage.getItem('user'));
    return  (
        <div style={styles.flex}>
            <div style={styles.singleCol}>
                {user ?
                    // <Redirect to={`${user.role}/%2Fapi%2F${user.role}%2F${user.id}`}/>
                    <Redirect to={`your_courses`}/>
                    :
                    <Redirect to='/login'/>
                }
            </div>
        </div>
    );
};

export default Dashboard;
