import React from "react";
import { Redirect, Route } from "react-router-dom";
import {
    HydraAdmin,
    hydraDataProvider as baseHydraDataProvider,
    fetchHydra as baseFetchHydra,
    ResourceGuesser,
} from "@api-platform/admin";
import parseHydraDocumentation from "@api-platform/api-doc-parser/lib/hydra/parseHydraDocumentation";
import authProvider from "./authProvider";

// import { RabbitMqInterface } from 'rabbitode'; removed
// import amqplib from "amqplib/callback_api"; removed

// import SockJsClient from 'react-stomp';removed
// import Stomp  from 'stompjs';removed

// import Stomp  from '@stomp/stompjs';
import webstomp from 'webstomp-client';
//
// import mqtt  from 'mqtt';

import users from "./components/users";
import bookings from "./components/bookings";
import courses from "./components/courses";
import your_courses from "./components/your_courses";
import payments from "./components/payments";
import booking_statuses from "./components/booking_statuses";
import curriculum from "./components/curriculum";
import weeks from "./components/weeks";
import homeWorks  from './components/home_works';
import notifications  from './components/notifications';

import { Login, Layout } from './layout';
import polyglotI18nProvider from 'ra-i18n-polyglot';
import germanMessages from './i18n/de';
import englishMessages from './i18n/en';
import customRoutes from './routes';
import themeReducer from './themeReducer';
import recordReducer from './recordReducer';
import permissionReducer from './permissionReducer';
import notificationReducer from './Reducers/notificationReducer';

import { Dashboard } from './components/dashboard';

const lang = localStorage.getItem('lang');
const localLang = (lang === 'en') ? 'de':lang;

const i18nProvider = polyglotI18nProvider(locale => {
    if(lang === 'en'){
        if (locale === 'de') {
            return import('./i18n/de').then(messages => messages.default);
        }
        return englishMessages;

    }else{
        if (locale === 'en') {
            return import('./i18n/en').then(messages => messages.default);
        }
        return germanMessages;
    }

},lang);

// const entrypoint = process.env.REACT_APP_API_ENTRYPOINT;
const entrypoint =process.env.REACT_APP_BACKEND_URL+'/api';
const fetchHeaders = (window.localStorage.getItem("token")) ? { Authorization: `Bearer ${window.localStorage.getItem("token")}` }: null;
const h =  (fetchHeaders) ? { headers:new Headers(fetchHeaders)} :{};
const fetchHydra = (url, options = {}) => baseFetchHydra(url, {
    ...options,
     headers: (fetchHeaders) ? new Headers(fetchHeaders) :'',
});
const apiDocumentationParser = entrypoint => parseHydraDocumentation(entrypoint, h)
    .then(
        ({ api }) => ({ api }),
        (result) => {
            switch (result.status) {
                case 401:
                    localStorage.clear();
                    return Promise.resolve({
                        api: result.api,
                        customRoutes: [
                            <Route path="/" render={() => {
                                    return window.localStorage.getItem("token")  ? window.location.reload()  : <Redirect to="/login" />
                            }} />
                        ],
                    });

                default:
                    return Promise.reject(result);
            }
        },
    );
// const apiDocumentationParser = entrypoint => parseHydraDocumentation(entrypoint, { headers: new Headers(fetchHeaders) });
const dataProvider = baseHydraDataProvider(entrypoint, fetchHydra, apiDocumentationParser);

const convertFileToBase64 = file =>
    new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = () => resolve(reader.result);
        reader.onerror = reject;

        reader.readAsDataURL(file);
    });



const url = "ws://localhost:15674/ws";
/*const  client  = mqtt.connect(url);

client.on('connect', function () {

    console.log("connect");
})
client.subscribe('sync', function (err,data) {
    console.log("dccc")
})
client.on('message', function (topic, message) {
    // message is Buffer
    console.log(message.toString());
    // client.end()
});*/

// var client = Stomp.client(url);
/*const client = webstomp.client(url);
client.connect('guest', 'guest',
    function() {
        console.log('connected');
        /!*client.onreceive=(frame)=>{
            console.log('Message received',frame)
        };*!/
        client.subscribe("messages", function(msg){
            console.log(msg);
        });
    },  function() {
        console.log('error');
    }, '/');*/


const myDataProvider = {
    ...dataProvider,
    /*getList:(resource, params)=>{
        console.log(resource);
        console.log(params);
        const user = JSON.parse(localStorage.getItem('user'));
        const par = {
            filter:{user: user.id},
            pagination:{page :1, perPage:10000000000000000},
            sort:{field:"id", order:"ASC"}
        };
        dataProvider.getList("notifications", par).then((d)=>{
            console.log(d);
            user.notifications = d.data.map((v)=>{
                return v;
            });
            console.log(d.data)
            // localStorage.setItem('user',JSON.stringify(user));
        });
        return dataProvider.getList(resource, params);
    },*/
    create: (resource, params) => {
        if(resource==='curricula'){
            // params['data']['course'] = localStorage.getItem('courseId');
            params['data']['booking'] = localStorage.getItem('bookingId');
        }

        return dataProvider.create(resource, params);
    },
    update: (resource, params) => {
        if(resource === 'customers'){
            for (let propName in params['data']) {
                if (params['data'][propName] === null || params['data'][propName] === undefined) {
                    params['data'][propName] = "";
                }
            }
        }
        return dataProvider.update(resource, params);


    },

};


export default props => (
    <HydraAdmin
                // apiDocumentationParser={ apiDocumentationParser }
                dataProvider={ myDataProvider }
                authProvider={ authProvider }
                entrypoint={ entrypoint }
                // appLayout={users.MyLayout}
                customReducers={{ theme: themeReducer, courseRecord: recordReducer, notification: notificationReducer, permissions: permissionReducer}}
                layout={Layout}
                loginPage={Login}
                i18nProvider={i18nProvider}
                customRoutes={customRoutes}
                dashboard={Dashboard}
    >
        <ResourceGuesser name="customers"    {...users} />
        <ResourceGuesser name="courses" {...courses} />
        <ResourceGuesser name="your_courses" {...your_courses}/>
        <ResourceGuesser name="home_works" {...homeWorks}/>
        <ResourceGuesser name="curricula" {...curriculum}/>
        <ResourceGuesser name="forget_password"/>
        <ResourceGuesser name="reset_password"/>
        <ResourceGuesser name="weeks" {...weeks}/>
        <ResourceGuesser name="payments" {... payments}/>
        <ResourceGuesser name="booking_statuses" {...booking_statuses} />
        <ResourceGuesser name="bookings" {...bookings}  />
        <ResourceGuesser name="notifications" {...notifications} />
    </HydraAdmin>
);