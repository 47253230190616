import React from 'react';
import { Route } from 'react-router-dom';
import Configuration from './configuration/Configuration';
import Shipping from './shipping/Shipping';
// import Notification from './components/notifications/Notification';
import Answer from './components/home_works/Answer';
// import ForgotPassword from "./layout/ForgotPassword";
export default [
    <Route exact path="/configuration" render={() => <Configuration />} />,
    <Route exact path="/shipping" render={() => <Shipping />} />,
    // <Route exact path="/notification" render={() => <Notification />} />,
    <Route exact path="/answer" render={() => <Answer />} />,
    // <Route exact path="/forgot-password" render={() => <ForgotPassword />}  />,
];