import Iframe from "react-iframe";
import React from "react";

const articulateCoursesUrl = process.env.REACT_APP_ARTICULATE_COURSES_FTP;
const endpoint = process.env.REACT_APP_XAPI_LRS_ENDPOINT;
const auth = process.env.REACT_APP_XAPI_AUTH;
const registration = process.env.REACT_APP_XAPI_REGISTRATION;

const IframeField = ({ record }) => {
    const user = JSON.parse(localStorage.getItem('user'));
    const actor = JSON.stringify({"name": [user.firstName+" "+user.lastName], "mbox": ["mailto:"+user.email]});
    const activity_id = record.articulateId;
    return record ? (
        <Iframe url= {`${articulateCoursesUrl}${record.name}/story.html?endpoint=${endpoint}&auth=${auth}&actor=${actor}&activity_id=http://${activity_id}&registration=${registration}#/`}
                width="100%"
                height="700px"
                id="myId"
                className="myClassname"
                display="initial"
                position="relative"/>
    ) : null;
};
export default IframeField;